<template>
  <div class="container-page big">
    <div class="title-block-top title-block-top--full">
      <div class="title-block-top__first title-block-top__first--full">
        <h2 class="title title--h2">Деловые советы (ДС)</h2>
      </div>
      <div class="title-block-top__second title-block-top__second--full">
        <div class="commision-button-container">
          <div class="commision-button-container__left">
            <ButtonComponent @click="createCard" mod="gradient-bg" class="button-redesign--mobile-full"
              >Создать ДС</ButtonComponent
            >
          </div>
          <div class="commision-button-container__right">
            <ButtonComponent @click="exportExcel" mod="gradient-bg" class="button-redesign--mobile-full"
              >Экспорт</ButtonComponent
            >
          </div>
        </div>
      </div>
    </div>

    <div class="commision-mpk__table table-container-base">
      <table>
        <thead>
          <tr>
            <th class="column-icon"></th>
            <th class="column-DS">Наименование ДС</th>
            <th class="column-DS">Страна</th>
            <th class="column-DS">Председатель Российской части ДС</th>
            <th class="column-DS">Председатель иностранной части ДС</th>
            <th class="column-DS">Дата заседания Российской части ДС</th>
            <th class="column-DS">Дата заседания ДС</th>
          </tr>
          <tr>
            <th class="column-icon"></th>
            <th class="column-DS">
              <ComponentInput placeholder="Название" mod="rectangular" v-model="filter.name" />
            </th>
            <th class="column-DS">
              <SelectMultiComponent
                v-model="filter.countryId"
                :options="countryList"
                placeholder="Страна"
                modifier="rectangular"
                class="visible-clear"
              />
            </th>
            <th class="column-DS">
              <ComponentInput placeholder="Председатель" mod="rectangular" v-model="filter.chairman" />
            </th>
            <th class="column-DS">
              <ComponentInput placeholder="Председатель" mod="rectangular" v-model="filter.foreignChairman" />
            </th>
            <th class="column-DS">
              <DateComponent
                v-model="filter.lastEventRussianPartFrom"
                modLabel="big"
                :dateIcon="false"
                modClass="filter"
                :upperLimit="getMeetingDateRPUpperLimit"
              />
              <DateComponent
                v-model="filter.lastEventRussianPartTo"
                modLabel="big"
                :dateIcon="false"
                modClass="filter"
                :lowerLimit="new Date(filter.lastEventRussianPartFrom)"
              />
            </th>
            <th class="column-DS">
              <DateComponent
                v-model="filter.lastEventFrom"
                modLabel="big"
                :dateIcon="false"
                modClass="filter"
                :upperLimit="getMeetingDateUpperLimit"
              />
              <DateComponent
                v-model="filter.lastEventTo"
                modLabel="big"
                :dateIcon="false"
                modClass="filter"
                :lowerLimit="new Date(filter.lastEventFrom)"
              />
            </th>
          </tr>
        </thead>
        <TableBody :itemsBody="itemsTable" :isLoading="isLoading">
          <tr
            v-for="(item, key) in itemsTable"
            :key="'business-tips-' + key"
            @dblclick="editCard(item.id)"
            class="hover-tr"
          >
            <td class="column-icon">
              <div class="wrap-icon">
                <IconComponent class="icon--full-svg" @click.prevent.stop="editCard(item.id)" name="edit" />
                <IconComponent
                  class="icon--full-svg delete"
                  @click.prevent.stop="deleteCard(item.id)"
                  name="delete-not-fill"
                />
              </div>
            </td>
            <td class="column-DS">{{ item.name }}</td>
            <td class="column-DS">{{ getNameCountry(item.countryId) }}</td>
            <td class="column-DS">
              <span class="name">{{ item.chairman }}</span>
              <br />
              {{ item.chairmanPost }}
            </td>
            <td class="column-DS">
              <span class="name">{{ item.foreignChairman }}</span>
              <br />
              {{ item.foreignChairmanPost }}
            </td>
            <td class="column-DS">
              {{ getPreparedDateColumn(item.meetingDateRP) }}
            </td>
            <td class="column-DS">
              {{ getPreparedDateColumn(item.meetingDate) }}
            </td>
          </tr>
        </TableBody>
      </table>
    </div>

    <template v-if="page.pagesTotal > 1">
      <PaginationRedesigned
        :page-count="page.pagesTotal"
        :current-page="page.pageNumber"
        @change="onPageChange($event)"
        @more="onPageMore($event)"
      />
    </template>
  </div>
</template>

<script>
  import { debounce } from 'lodash';
  import moment from 'moment';

  import ComponentInput from '@/common/components/ComponentInput';
  import DateComponent from '@/common/components/dateComponent.vue';
  import ButtonComponent from '@/common/components/redesigned/ButtonComponent.vue';
  import PaginationRedesigned from '@/common/components/redesigned/PaginationRedesigned';
  import TableBody from '@/common/components/redesigned/TableBody';
  import SelectMultiComponent from '@/common/components/SelectMultiComponent.vue';
  import Constants from '@/common/constants';
  import IconComponent from '@/common/ui/IconComponent.vue';
  import Utils from '@/common/utils';

  import API from '../api/business-tips';

  export default {
    name: 'BusinessTips',
    components: {
      DateComponent,
      ButtonComponent,
      IconComponent,
      PaginationRedesigned,
      ComponentInput,
      SelectMultiComponent,
      TableBody,
    },
    data() {
      return {
        isLoading: false,
        countryList: [],
        filter: {
          name: '',
          countryId: this?.$route.query?.countryId || '',
          chairman: '',
          foreignChairman: '',
          lastEventFrom: null,
          lastEventTo: null,
          lastEventRussianPartFrom: null,
          lastEventRussianPartTo: null,
        },
        page: {
          pageNumber: 1,
          pageSize: 9,
          pagesTotal: 0,
        },
        itemsTable: [],
      };
    },
    computed: {
      getMeetingDateUpperLimit() {
        if (!this.filter.lastEventTo) return;
        return new Date(this.filter.lastEventTo);
      },
      getMeetingDateRPUpperLimit() {
        if (!this.filter.lastEventRussianPartTo) return;
        return new Date(this.filter.lastEventRussianPartTo);
      },
    },
    created() {
      this.isLoading = true;

      Utils.searchNsi('countryListWithData', {}).then((response) => {
        this.countryList = response.data.map((i) => ({
          value: i.id,
          label: i.nameFull || i.name,
        }));
      });

      this.search();
    },
    methods: {
      createCard() {
        this.$router.push({ name: 'card-business-tips', params: { id: 0, type: 'create' } });
      },

      getPreparedDateColumn(date) {
        if (!date) return '-';
        return moment(date).format('DD.MM.YYYY');
      },

      exportExcel() {
        API.exportExcel();
      },

      search() {
        API.getSearchBusinessCard({
          ...this.filter,
          ...this.page,
        })
          .then((res) => {
            this.itemsTable = res.data.items;
            this.page.pageNumber = res.data.pageNumber;
            this.page.pagesTotal = res.data.pagesTotal;
            this.isLoading = false;
          })
          .catch((err) => {
            console.error(err);
          });
      },

      getNameCountry(id) {
        const index = this.countryList.findIndex((i) => +i.value === +id);
        if (index !== -1) {
          return this.countryList[index].label;
        }
      },

      editCard(id) {
        this.$router.push({ name: 'card-business-tips', params: { id, type: 'edit' } });
      },

      deleteCard(id) {
        API.deleteBusinessCard(id)
          .then(() => {
            this.isLoading = true;
            this.page.pageNumber = 1;
            this.search();
          })
          .catch((err) => {
            console.error('error deleteCard', err);
            Constants.alert.fire('Неудалось удалить карточку', Constants.commonError, 'error');
          });
      },

      debounceChangeValue: debounce(function () {
        this.search();
      }, 500),

      onPageChange(pageNumber) {
        this.page.pageNumber = pageNumber;
        this.search();
      },

      onPageMore(pageNumber) {
        this.page.pageNumber = pageNumber;
        API.getSearchBusinessCard({
          ...this.filter,
          ...this.page,
        })
          .then((res) => {
            this.itemsTable = this.itemsTable.concat(res.data.items);
          })
          .catch((err) => {
            console.error(err);
          });
      },
    },
    watch: {
      filter: {
        handler() {
          this.page.pageNumber = 1;
          this.debounceChangeValue();
        },
        deep: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/theme/default/title.scss';
  @import '@/assets/theme/default/table/table-base.scss';

  .commision-button-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    &__right {
      display: flex;
      flex-wrap: wrap;
    }

    @media (max-width: $mobile) {
      &__left,
      &__right {
        width: 100%;
      }

      &__left {
        margin-bottom: 8px;
      }

      &__filter {
        margin-top: 8px;
      }
    }
  }

  .column-DS {
    min-width: 200px;
    max-width: 200px;
  }
</style>
