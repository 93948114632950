import axios from 'axios';

import Utils from '@/common/utils';

export default {
  // экспорт таблицы
  exportExcel(params) {
    return Utils.downloadFile('/api/mpkbusinesstip/exportexcel', 'BusinessTips.xlsx', params);
  },

  // получение списка ДС
  getSearchBusinessCard(params) {
    return axios.get(`/api/mpkbusinesstip/search`, { params });
  },

  // Получение данных по детальной ДС
  getBusinessCardDetail(params) {
    return axios.get(`/api/mpkbusinesstip/find`, { params });
  },

  // Получение Председателей РФ ДС
  getParticipants() {
    return axios.get('/api/mpkbusinesstip/participants');
  },

  // Получение Председателей Иностранной части ДС
  getForeignParticipants() {
    return axios.get('/api/mpkbusinesstip/foreignparticipants');
  },

  // Создание карточки ДС
  // params:
  // chairman: string
  // name: string
  // countryId: int
  postCreateBusinessCard(params) {
    return axios.post('/api/mpkbusinesstip/create', params);
  },

  // Редактирование карточки ДС
  // params:
  // id: int
  // chairman: string
  // name: string
  // countryId: int
  putEditBusinessCard(params) {
    const { id = '', data = {} } = params;
    return axios.put(`/api/mpkbusinesstip/update?id=${id}`, { id, ...data });
  },

  // Удаление карточки ДС
  deleteBusinessCard(id) {
    return axios.delete(`/api/mpkbusinesstip/delete?id=${id}`);
  },
};
